import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Profile from './components/Profile/Profile';
import Home from './components/Home/Home';
import AuthPage from './pages/AuthPage/AuthPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import TestRegistration from './pages/TestRegistration/TestRegistration';
import Leads from './components/Leads/Leads';
import AgentLeads from './components/AgentLeads/AgentLeads';
import LeadsWrapper from './components/Leads/LeadsWrapper';

const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path='/' element={<PrivateRoute />}>
        <Route exact path='/' element={<Home />} />
      </Route>
      {/* TODO: remove late */}
      {/* <Route path='/test-reg' element={<PrivateRoute />}>
        <Route path='/test-reg' element={<TestRegistration />} />
      </Route> */}
      {/* <Route exact path='/profile' element={<PrivateRoute />}>
        <Route exact path='/profile' element={<Profile />} />
      </Route> */}
      <Route exact path='/leads' element={<PrivateRoute />}>
        <Route exact path='/leads' element={<LeadsWrapper />} />
      </Route>
      <Route exact path='/agent-leads' element={<PrivateRoute />}>
        <Route exact path='/agent-leads' element={<AgentLeads />} />
        <Route exact path='/agent-leads/:id' element={<AgentLeads />} />
      </Route>
      <Route path='/auth/:authType' element={<AuthPage />} />
      <Route path='/auth/:authType/:id' element={<AuthPage />} />
      <Route path='*' element={<Navigate to='/auth/login' />} />
    </Routes>
  );
};

export default AppRoutes;
