import React from 'react';
import cn from 'classnames';

import './style.css';

const Container = ({ className, children, ...rest }) => {
  return (
    <div className={cn(className, 'container')} {...rest}>
      {children}
    </div>
  );
};

export default Container;
