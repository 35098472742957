import { message } from 'antd';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAgentLeadsData, getLeads } from '../redux/leads/leadsSlice';

const useFetchAgentLeads = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const leads = useSelector(getLeads);

  const getAgentLeadsData = useCallback(
    async (id = 'all') => {
      setLoading(true);
      return dispatch(fetchAgentLeadsData(id))
        .then((response) => {
          if (response.error) {
            throw new Error(response.error.message);
          }
        })
        .catch((error) => {
          const errorString = `Error While Getting Agent Leads With Message: ${error}`;
          console.error(errorString);
          message.error('Error While Getting Leads');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, leads]
  );

  return { loading, getAgentLeadsData, leads };
};

export default useFetchAgentLeads;
