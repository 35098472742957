import { Button, Row, Select, Space, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import { CopyOutlined } from '@ant-design/icons';
import MainLayout from '../MainLayout/MainLayout';
import { getUser } from '../../redux/user/userSlice';
import useFetchProjects from '../../hooks/useFetchProjects';

import './style.css';

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const { projects } = useFetchProjects();
  const user = useSelector(getUser);
  const [projectId, setProjectId] = useState(0);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!user || !projects?.length) {
      setLoading(true);
      return;
    }

    const url = new URL(`${projects[projectId].url}`);
    const project = projects[projectId].hash;
    url.searchParams.append('refcode', user.hash);
    url.searchParams.append('project', project);
    setUrl(url.toString());
    setLoading(false);
  }, [user, projects, projectId]);

  const handleSelect = (value) => {
    setProjectId(getProjectIdByValue(value));
  };

  const getProjectValue = () => {
    if (!projects?.length) {
      return '';
    }
    return projects[projectId].value;
  };

  const getProjectIdByValue = (value) => {
    const index = projects.findIndex((item) => item.value === value);
    return index === -1 ? 0 : index;
  };

  const copyToClickboard = useCallback(() => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [url]);

  if (loading) {
    return <Spin size='large' />;
  }
  return (
    <MainLayout>
      <Space size={40} direction='vertical' align='center' className='home-space'>
        <Row className='home-row-wrapper'>
          <Select
            defaultValue={getProjectValue}
            style={{
              minWidth: 240,
            }}
            onChange={handleSelect}
            options={projects}
          />
          <a href={url} className='home-reflink' target='_blank' rel='noopener noreferrer'>
            {url}
          </a>
          <div style={{ position: 'relative' }}>
            <Button icon={<CopyOutlined style={{ fontSize: '16px' }} />} onClick={copyToClickboard} disabled={copied}>
              Copy to clickboard
            </Button>
            {copied && <div className='copied-notification'>Copied!</div>}
          </div>
        </Row>
        <Row justify='center'>
          {url && (
            <QRCode
              size={256}
              style={{ height: '256px', maxWidth: '100%', width: '256px' }}
              value={url}
              viewBox={`0 0 256 256`}
            />
          )}
        </Row>
      </Space>
    </MainLayout>
  );
};

export default Home;
