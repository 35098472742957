import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchAgentLeads from '../../hooks/useFetchAgentLeads';
import useFetchUsers from '../../hooks/useFetchUsers';
import { getUser, isAdmin } from '../../redux/user/userSlice';
import Leads from '../Leads/Leads';

const AgentLeads = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { loading: loadingLeads, leads, getAgentLeadsData } = useFetchAgentLeads();
  const { loading: loadingUsers, users, getUsersData } = useFetchUsers();

  const isUserAdmin = useSelector(isAdmin);
  const navigate = useNavigate();
  const user = useSelector(getUser);

  useEffect(() => {
    getAgentLeadsData(id);
  }, [id]);

  useEffect(() => {
    if (!user) {
      setLoading(true);
      return;
    }
    if (!isUserAdmin) {
      navigate('/');
    } else {
      getUsersData();
      setLoading(false);
    }
  }, [user, isUserAdmin]);

  if (loading || loadingLeads || loadingUsers) {
    return <Spin size='large' />;
  }

  return <Leads currentAgent={id} isAdmin={isUserAdmin} leads={leads} users={users} />;
};

export default AgentLeads;
