import { Button, Col, Row, Select } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useScreenSize from '../../hooks/useScreenSize';
import LeadsModal from '../LeadsModal/LeadsModal';
import LeadsTable from '../LeadsTable/LeadsTable';
import LeadsTableMobile from '../LeadsTableMobile/LeadsTableMobile';
import MainLayout from '../MainLayout/MainLayout';

import './style.css';

const Leads = ({ isAdmin, leads, users, currentAgent }) => {
  const { isMobile } = useScreenSize();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const editedLead = useRef(null);
  const [typeModal, setTypeModal] = useState('new');
  const navigate = useNavigate();

  const handleEdit = (record) => {
    editedLead.current = record;
    setTypeModal('edit');
    setIsModalOpen(true);
  };

  const newLead = () => {
    editedLead.current = null;
    setTypeModal('new');
    setIsModalOpen(true);
  };

  const hideModal = () => {
    editedLead.current = null;
    setIsModalOpen(false);
  };

  const handleSelect = (value) => {
    if (value === 'empty') {
      navigate(`/agent-leads/0`);
      return;
    }
    const id = users.find((user) => user.value === value)?.id;
    navigate(`/agent-leads/${id || ''}`);
  };

  const getCurrentAgent = useCallback(() => {
    if (Number(currentAgent) === 0) {
      return 'empty';
    }
    const currentUser = users.find((user) => user.id === Number(currentAgent));
    return currentUser?.value || 'all';
  }, [users, currentAgent]);

  return (
    <MainLayout>
      <Row align='middle' justify='space-between' style={{ marginBottom: '20px', marginTop: '20px' }}>
        <Col>
          <h3 style={{ marginBottom: 0 }}>Leads</h3>
        </Col>
        {isAdmin && (
          <Select className='user-select' defaultValue={getCurrentAgent} onChange={handleSelect}>
            <Select.Option value='all'>All</Select.Option>
            <Select.Option value='empty'>No agent</Select.Option>
            {users.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        )}
        <Col>
          <Button onClick={newLead}>Add lead</Button>
        </Col>
      </Row>
      <div style={{ marginBottom: '20px' }}>
        {isMobile ? (
          <LeadsTableMobile isAdmin={isAdmin} records={leads} editLead={handleEdit} />
        ) : (
          <LeadsTable isAdmin={isAdmin} records={leads} />
        )}
      </div>
      <LeadsModal
        isAdmin={isAdmin}
        type={typeModal}
        record={editedLead.current}
        isVisible={isModalOpen}
        hideModal={hideModal}
      />
    </MainLayout>
  );
};

export default Leads;
