import React, { useEffect } from 'react';
import { Layout, message, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AppHeader from '../Appheader/Appheader';
import AppRoutes from '../../Routes';
import useFetchUser from '../../hooks/useFetchUser';
import useFetchProjects from '../../hooks/useFetchProjects';
import auth from '../../utils/auth';
import { removeUser } from '../../redux/user/userSlice';

import './style.css';
import useFetchStatuses from '../../hooks/useFetchStatuses';

const { Header, Content } = Layout;

const App = () => {
  const authToken = auth.getToken();
  const { loading: loading1, getUserData } = useFetchUser();
  const { loading: loading2, getProjectsData } = useFetchProjects();
  const { loading: loading3, getStatusesData } = useFetchStatuses();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken) {
      return;
    }

    if (authToken) {
      getUserData().then((data) => {
        if (data?.error) {
          message.error(data?.error?.message || 'Error While Getting Logged In User Details');
          auth.clearToken();
          dispatch(removeUser());
          navigate('/auth/login', { replace: true });
        }
      });
      getProjectsData();
      getStatusesData();
    }
  }, [authToken, getUserData, getProjectsData, getStatusesData, dispatch]);

  if (loading1 || loading2 || loading3) {
    return <Spin size='large' />;
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <Header className='header'>
        <AppHeader />
      </Header>
      <Content style={{ height: '100%' }}>
        <AppRoutes />
      </Content>
    </Layout>
  );
};

export default App;
