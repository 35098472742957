import React, { useState } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { copyToClipboard } from '../../utils/helpers';

const CopyIcon = ({ content }) => {
  const [disabled, setDisabled] = useState(false);

  const handleCopy = () => {
    setDisabled(true);
    copyToClipboard(content)
      .then(() => {
        message.success('Copied!');
      })
      .catch(() => {
        message.error('Error while copy');
      })
      .finally(() => {
        setTimeout(() => {
          setDisabled(false);
        }, 3000);
      });
  };

  return (
    <Button
      style={{ border: 'none', background: 'transparent', flexShrink: 0 }}
      icon={<CopyOutlined style={{ fontSize: '12px' }} />}
      onClick={handleCopy}
      disabled={disabled}
    />
  );
};

export default CopyIcon;
