import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createLeadApiData,
  fetchLeadsApiData,
  updateLeadApiData,
  fetchAgentLeadsApiData,
  deleteLeadApiData,
} from '../../api/leads';
import { fetchStatusesApiData } from '../../api/statuses';
import { fetchProjectsApiData } from '../../api/projects';

const initialState = {
  leads: [],
  statuses: [],
  projects: [],
};

export const fetchLeadsData = createAsyncThunk('leads/fetchLeads', async (options) => {
  const response = await fetchLeadsApiData(options);
  return response.data;
});

export const fetchAgentLeadsData = createAsyncThunk('leads/fetchAgentLeads', async (id, options) => {
  const response = await fetchAgentLeadsApiData(id, options);
  return response.data;
});

export const updateLeadData = createAsyncThunk('leads/updateLead', async ({ id, options }) => {
  const response = await updateLeadApiData(id, options);
  return response.data;
});

export const createLeadData = createAsyncThunk('leads/createLead', async (options) => {
  const response = await createLeadApiData(options);
  return response.data;
});

export const fetchStatusesData = createAsyncThunk('leads/fetchStatuses', async () => {
  const response = await fetchStatusesApiData();
  return response.data.map((item) => ({
    ...item,
    ...item.attributes,
    value: item.attributes.type,
    label: item.attributes.title,
  }));
});

export const fetchProjectsData = createAsyncThunk('leads/fetchProjects', async () => {
  const response = await fetchProjectsApiData();
  return response.data.map((item) => ({
    ...item,
    ...item.attributes,
    value: item.attributes.name,
    label: item.attributes.name,
  }));
});

export const deleteLeadData = createAsyncThunk('leads/deleteLead', async ({ id, options }) => {
  const response = await deleteLeadApiData(id, options);
  return response.data;
});

const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadsData.fulfilled, (state, action) => {
        state.leads = action.payload;
      })
      .addCase(fetchAgentLeadsData.fulfilled, (state, action) => {
        state.leads = action.payload;
      })
      .addCase(updateLeadData.fulfilled, (state, action) => {
        const { id, status, project } = action.payload;
        const prev = state.leads;
        const index = state.leads.findIndex((item) => item.id === id);
        if (index === -1) {
          return;
        }
        const newItem = {
          ...state.leads[index],
          ...action.payload,
          status: state.statuses.find((item) => item.id === status),
          project: state.projects.find((item) => item.id === project),
        };
        prev.splice(index, 1, newItem);
        state.leads = prev;
      })
      .addCase(createLeadData.fulfilled, (state, action) => {
        const { id, status, project } = action.payload;
        const prev = state.leads;
        const newLead = {
          ...action.payload,
          status: state.statuses.find((item) => item.id === status),
          project: state.projects.find((item) => item.id === project),
        };
        prev.unshift(newLead);
        state.leads = prev;
      })
      .addCase(fetchStatusesData.fulfilled, (state, action) => {
        state.statuses = action.payload;
      })
      .addCase(fetchProjectsData.fulfilled, (state, action) => {
        // TODO: нужно добавить возможность пагниации/ подгрузки новых проектов и добавление к существующим
        state.projects = action.payload;
      })
      .addCase(deleteLeadData.fulfilled, (state, action) => {
        const { id } = action.payload;
        const index = state.leads.findIndex((item) => item.id === id);
        if (index === -1) {
          return;
        }
        state.leads.splice(index, 1);
      });
  },
});

export const getLeads = (state) => state.leads.leads;
export const getStatuses = (state) => state.leads.statuses;
export const getProjects = (state) => state.leads.projects;

export default leadsSlice.reducer;
