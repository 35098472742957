import { Button, Col, Row, Tag } from 'antd';
import React, { useCallback } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { FiTrash } from 'react-icons/fi';

import CopyIcon from '../CopyIcon/CopyIcon';

import './style.css';
import { COLOR_STATUS } from '../../utils/constant';
import { useDispatch } from 'react-redux';
import { deleteLeadData } from '../../redux/leads/leadsSlice';

const LeadsTableMobile = ({ records = [], editLead, isAdmin }) => {
  const dispatch = useDispatch();

  const remove = useCallback(
    async (record) => {
      const res = window.confirm('Are you sure delete this lead?');
      if (!res) {
        return;
      }
      const { id } = record;
      await dispatch(
        deleteLeadData({
          id,
          options: {
            method: 'DELETE',
          },
        })
      );
    },
    [dispatch]
  );

  if (!records.length) {
    return (
      <div className='leads-table'>
        <h3 style={{ textAlign: 'center' }}>No data</h3>
      </div>
    );
  }

  return (
    <div className='leads-table'>
      {records.map((record) => (
        <div key={record.id} className='leads-table__item'>
          <div className='leads-table__item-col'>
            <div className='row'>
              <a href={`tel:${record.phone}`}>{record.phone}</a>
              <CopyIcon content={record.phone} />
            </div>
            {(record.name || record.status) && (
              <div className='row'>
                {record.name && <h3 style={{ margin: '0 15px 0 0' }}>{record.name}</h3>}
                <Tag color={COLOR_STATUS[record.status?.type || 'new']}>{record.status?.title?.toUpperCase()}</Tag>
              </div>
            )}
          </div>
          <Row gutter={8} align={'middle'}>
            <Col>
              <Button onClick={() => editLead(record)} icon={<EditOutlined />} />
            </Col>
            {isAdmin && (
              <Col>
                <Button icon={<FiTrash />} onClick={() => remove(record)} />
              </Col>
            )}
          </Row>
        </div>
      ))}
    </div>
  );
};

export default LeadsTableMobile;
