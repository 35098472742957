import React, { useCallback, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Select, Table, Tag } from 'antd';
import tableJSON from './table.json';
import { COLOR_STATUS } from '../../utils/constant';
import { getFormatedDateAndMonth } from '../../utils/helpers';
import CopyIcon from '../CopyIcon/CopyIcon';
import { useEffect } from 'react';
import { SaveOutlined, EditOutlined } from '@ant-design/icons';
import { ImCancelCircle } from 'react-icons/im';
import { FiTrash } from 'react-icons/fi';

import './style.css';
import { useDispatch } from 'react-redux';
import { deleteLeadData, updateLeadData } from '../../redux/leads/leadsSlice';
import useFetchStatuses from '../../hooks/useFetchStatuses';

const VALIDATED_FIELDS = tableJSON.columns.filter((item) => item.required).map((item) => item.dataIndex);

const EditableCell = (props) => {
  const { editing, dataIndex, title, record, type, children, required, ...restProps } = props;

  const { statuses } = useFetchStatuses();
  let inputNode;

  switch (dataIndex) {
    case 'status':
      inputNode = <Select options={statuses} />;
      break;
    case 'tel':
      inputNode = (
        <InputNumber
          controls={false}
          style={{ width: '100%' }}
          formatter={(value) => `+${value.replace(/[^+\d]/g, '')}`}
          type={type}
        />
      );
      break;
    default:
      inputNode = ['number'].includes(type) ? (
        <InputNumber controls={false} style={{ width: '100%' }} type={type} />
      ) : (
        <Input type={type} />
      );
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const LeadsTable = ({ records, isAdmin }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingId, setEditingId] = useState(-1);
  const isEditing = (record) => record.id === editingId;
  const dispatch = useDispatch();
  const { statuses } = useFetchStatuses();

  useEffect(() => {
    if (records?.length) {
      setData(records.map((rec, index) => ({ ...rec, key: index })));
    }
  }, [records, setData]);

  const remove = useCallback(
    async (record) => {
      const res = window.confirm('Are you sure delete this lead?');
      if (!res) {
        return;
      }
      const { id } = record;
      await dispatch(
        deleteLeadData({
          id,
          options: {
            method: 'DELETE',
          },
        })
      );
    },
    [dispatch]
  );

  const edit = useCallback(
    (record) => {
      form.setFieldsValue({
        ...record,
        status: record.status?.type || statuses[0].type,
      });
      setEditingId(record.id);
    },
    [form]
  );

  const cancel = useCallback(() => {
    setEditingId(-1);
  }, []);

  const save = useCallback(
    async (record) => {
      const { id } = record;
      try {
        const row = await form.validateFields(VALIDATED_FIELDS);
        const rowData = Object.entries(row).reduce((acc, [key, value]) => {
          const field = {};
          switch (key) {
            case 'status':
              field.status = statuses.find((item) => item.value === value)?.id;
              break;
            case 'phone':
              field.phone = `+${String(value).replace(/\D/g, '')}`;
              break;
            default:
              field[key] = String(value);
              break;
          }
          return Object.assign(acc, field);
        }, {});
        await dispatch(
          updateLeadData({
            id,
            options: {
              method: 'PUT',
              body: { data: rowData },
            },
          })
        );
        setEditingId(-1);
      } catch (errInfo) {
        console.error('Validate Failed:', errInfo);
      }
    },
    [dispatch, form, statuses]
  );

  const preparedColumns = tableJSON.columns
    .map(({ title, dataIndex, required = false, type = 'text', ...rest }) => {
      let renderFunc;
      switch (dataIndex) {
        case 'project':
          renderFunc = (_, { project }) => {
            return <>{project?.name}</>;
          };
          break;
        case 'status':
          renderFunc = (_, { status }) => {
            return <Tag color={COLOR_STATUS[status?.type || 'new']}>{status?.title?.toUpperCase()}</Tag>;
          };
          break;
        case 'updatedAt':
          renderFunc = (_, record) => {
            const { updatedAt } = record;
            const editable = isEditing(record);
            return editable ? (
              <Row gutter={8} align='middle'>
                <Col>
                  <Button onClick={() => save(record)} icon={<SaveOutlined />} />
                </Col>
                <Col>
                  <Button
                    onClick={cancel}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    icon={<ImCancelCircle />}
                  />
                </Col>
              </Row>
            ) : (
              <Row gutter={8} align='middle'>
                <Col>{getFormatedDateAndMonth(updatedAt)}</Col>
                <Col>
                  <Button icon={<EditOutlined />} disabled={editingId !== -1} onClick={() => edit(record)} />
                </Col>
                {isAdmin && editingId === -1 && (
                  <Col>
                    <Button icon={<FiTrash />} onClick={() => remove(record)} />
                  </Col>
                )}
              </Row>
            );
          };
          break;
        case 'phone':
          renderFunc = (_, { phone }) => {
            if (!phone) return null;
            return (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <a href={`tel:${phone}`}>{phone}</a>
                <CopyIcon content={phone} />
              </div>
            );
          };
          break;
        case 'email':
          renderFunc = (_, { email }) => {
            if (!email) return null;
            return (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <a href={`mailto:${email}`}>{email}</a>
                <CopyIcon content={email} />
              </div>
            );
          };
          break;
        default:
          break;
      }
      return {
        title,
        required,
        dataIndex,
        type,
        render: renderFunc,
        ...rest,
      };
    })
    .map((col) => {
      if (!col.editable) {
        if (col.dataIndex === 'updatedAt' && isAdmin) {
          Object.assign(col, { width: col.width + 30 });
        }
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          type: col.type,
          required: col.required,
          editing: isEditing(record),
        }),
      };
    });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={preparedColumns}
        scroll={{
          x: 860,
        }}
        pagination={false}
        sticky
      />
    </Form>
  );
};

export default LeadsTable;
