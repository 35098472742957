import React from 'react';
import { Button, Col, Row } from 'antd';
import { AiOutlineQrcode, AiOutlineCreditCard } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import { MdOutlinePeopleAlt, MdOutlineAdminPanelSettings } from 'react-icons/md';

import './style.css';
import { useSelector } from 'react-redux';
import { isAdmin } from '../../redux/user/userSlice';

const nav = [
  {
    href: '/',
    name: 'QR code',
    icon: <AiOutlineQrcode size={20} className='sidebar-icon' />,
  },
  // {
  //   href: '/profile',
  //   name: 'My profile',
  //   icon: <AiOutlineCreditCard size={20} className='sidebar-icon' />,
  // },
  {
    href: '/leads',
    name: 'Leads',
    icon: <MdOutlinePeopleAlt size={20} className='sidebar-icon' />,
  },
  {
    href: '/agent-leads',
    name: 'Agent Leads',
    icon: <MdOutlineAdminPanelSettings size={20} className='sidebar-icon' />,
    guard: 'admin',
  },
];

const Sidebar = () => {
  const isUserAdmin = useSelector(isAdmin);

  const filteredNav = isUserAdmin ? nav : nav.filter((item) => !item.guard);

  return (
    <div className='sidebar'>
      <div className='sidebar-wrapper'>
        <Row
          className='sidebar-row'
          gutter={[
            { xs: 8, sm: 16, md: 0 },
            { xs: 0, sm: 0, md: 16 },
          ]}
        >
          {filteredNav.map((item) => (
            <Col key={item.name} className='sidebar-item'>
              <NavLink
                to={item.href}
                className={({ isActive }) => (isActive ? 'sidebar-link _isActive' : 'sidebar-link')}
              >
                <Button className='sidebar-button'>
                  {item.icon}
                  {item.name}
                </Button>
              </NavLink>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Sidebar;
