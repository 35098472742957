import { AGENT_LEADS_API, LEADS_API } from '../utils/constant';
import request from '../utils/request';

export const fetchLeadsApiData = async (options) => {
  return await request(LEADS_API, options);
};

export const updateLeadApiData = async (id, options) => {
  return await request(`${LEADS_API}/${id}`, options);
};

export const createLeadApiData = async (options) => {
  return await request(LEADS_API, options);
};

export const fetchAgentLeadsApiData = async (id, options) => {
  return await request(`${AGENT_LEADS_API}/${id}`, options);
};

export const deleteLeadApiData = async (id, options) => {
  return await request(`${LEADS_API}/${id}`, options);
};
