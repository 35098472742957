import { message } from 'antd';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLeadsData, getLeads } from '../redux/leads/leadsSlice';

const useFetchLeads = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const leads = useSelector(getLeads);

  const getLeadsData = useCallback(
    async (force) => {
      if (leads.length && !force) {
        return leads;
      }
      setLoading(true);
      return dispatch(fetchLeadsData())
        .then((response) => {
          if (response.error) {
            throw new Error(response.error.message);
          }
        })
        .catch((error) => {
          const errorString = `Error While Getting Leads With Message: ${error}`;
          console.error(errorString);
          message.error('Error While Getting Leads');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, leads]
  );

  return { loading, getLeadsData, leads };
};

export default useFetchLeads;
