import { Spin } from 'antd';
import React, { useEffect } from 'react';

import useFetchLeads from '../../hooks/useFetchLeads';
import Leads from './Leads';

const LeadsWrapper = () => {
  const { loading, leads, getLeadsData } = useFetchLeads();

  useEffect(() => {
    getLeadsData(true);
  }, []);

  if (loading) {
    return <Spin size='large' />;
  }

  return <Leads leads={leads} />;
};

export default LeadsWrapper;
