import { configureStore } from '@reduxjs/toolkit';
import leadsReducer from './leads/leadsSlice';
import userReducer from './user/userSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    leads: leadsReducer,
  },
});
