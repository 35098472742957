import { Button, Form, Input, InputNumber, message, Modal, Select } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import useFetchProjects from '../../hooks/useFetchProjects';
import useFetchStatuses from '../../hooks/useFetchStatuses';
import useFetchUsers from '../../hooks/useFetchUsers';
import { createLeadData, updateLeadData } from '../../redux/leads/leadsSlice';

import fields from './fields.json';
import './style.css';

const getTitleText = (type) => {
  if (type === 'new') {
    return <>New lead</>;
  }
  return <>Edit lead</>;
};

const GetFormItem = ({ item }) => {
  const { type, ...rest } = item;
  const { statuses } = useFetchStatuses();
  const { projects } = useFetchProjects();
  const { users } = useFetchUsers();

  let inner;
  switch (type) {
    case 'select':
      if (item.name === 'status') {
        inner = <Select options={statuses} />;
      } else if (item.name === 'project') {
        inner = <Select options={projects} />;
      } else {
        inner = <Select options={users} />;
      }
      break;
    case 'tel':
      inner = (
        <InputNumber
          style={{ width: '100%' }}
          controls={false}
          formatter={(value, info) => {
            return `+${value.replace(/\D/g, '')}`;
          }}
          type={type}
        />
      );
      break;
    default:
      inner = ['number'].includes(type) ? <InputNumber controls={false} type={type} /> : <Input type={type} />;
      break;
  }

  return (
    <Form.Item {...rest} rules={[...(item.rules || '')]}>
      {inner}
    </Form.Item>
  );
};

const getFormatedFields = (values, { projects, statuses, users }) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const field = {};
    switch (key) {
      case 'status':
        field.status = statuses.find((item) => item.value === value)?.id;
        break;
      case 'project':
        field.project = projects.find((item) => item.value === value)?.id;
        break;
      case 'agent':
        field.agent = users.find((item) => item.value === value)?.id;
        break;
      case 'phone':
        field.phone = `+${String(value).replace(/\D/g, '')}`;
        break;
      default:
        field[key] = value ? String(value) : null;
        break;
    }
    return Object.assign(acc, field);
  }, {});
};

const LeadsModal = ({ isAdmin, record, type = 'new', isVisible, hideModal }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { statuses } = useFetchStatuses();
  const { projects } = useFetchProjects();
  const dispatch = useDispatch();
  const initialValues = useRef(null);
  const { users } = useFetchUsers();

  useEffect(() => {
    initialValues.current = { status: statuses?.[0]?.type, project: projects?.[0]?.name };
  }, [statuses, projects]);

  useEffect(() => {
    if (record) {
      const newState = Object.entries(record).reduce((arr, [key, value]) => {
        if (['updatedAt', 'createdAt'].includes(key)) {
          return arr;
        }
        if (key === 'project') {
          return Object.assign(arr, { project: value?.name });
        }
        if (key === 'status') {
          return Object.assign(arr, { status: value?.type || statuses[0].type });
        }
        if (key === 'agent') {
          return Object.assign(arr, { agent: value?.username });
        }
        if (key === 'phone') {
          return Object.assign(arr, { phone: +value });
        }
        return Object.assign(arr, { [key]: value });
      }, {});
      form.setFieldsValue(newState);
    } else {
      form.resetFields();
      form.setFieldsValue(initialValues.current);
    }
  }, [record, form, initialValues]);

  const handleOk = useCallback(
    async (fieldsValue) => {
      setLoading(true);
      const data = getFormatedFields(fieldsValue, { projects, statuses, users });
      try {
        if (type === 'new') {
          await dispatch(createLeadData({ method: 'POST', body: { data } }));
          message.success('Success creating!');
        } else {
          const { id } = record;
          await dispatch(
            updateLeadData({
              id,
              options: {
                method: 'PUT',
                body: { data },
              },
            })
          );
          message.success('Success updating!');
        }
      } catch (error) {
        console.error(error);
        if (type === 'new') {
          message.error('Error creating!');
        } else {
          message.error('Error updating!');
        }
      } finally {
        setLoading(false);
        setTimeout(() => {
          hideModal();
        }, 200);
      }
    },
    [dispatch, record, form, statuses, projects, users, type, hideModal]
  );

  return (
    <Modal
      title={getTitleText(type)}
      open={isVisible}
      onCancel={hideModal}
      footer={[
        <div key='footer' className='leads-modal-footer'>
          <Button onClick={hideModal} loading={loading}>
            Cancel
          </Button>
          <Button type='primary' loading={loading} onClick={() => formRef.current.submit()}>
            Apply
          </Button>
        </div>,
      ]}
    >
      <Form form={form} ref={formRef} layout='vertical' onFinish={handleOk}>
        {fields.map((field) => (!field.guard || isAdmin) && <GetFormItem item={field} key={field.name} />)}
      </Form>
    </Modal>
  );
};

export default LeadsModal;
