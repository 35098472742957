import { API, AUTH_TOKEN } from './constant';

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const getAuthURL = (authType) => {
  let requestURL;

  switch (authType) {
    case 'login':
      requestURL = `${API}/auth/local`;
      break;
    case 'register':
      requestURL = `${API}/auth/local/register`;
      break;
    case 'reset-password':
      requestURL = `${API}/auth/reset-password`;
      break;
    case 'forgot-password':
      requestURL = `${API}/auth/forgot-password`;
      break;
    default:
  }

  return requestURL;
};

export const getFormTitle = (authType) => {
  let title;

  switch (authType) {
    case 'login':
      title = `Sign In`;
      break;
    case 'register':
      title = `Sign Up`;
      break;
    case 'reset-password':
      title = `Reset Password`;
      break;
    case 'forgot-password':
      title = `Forgot Password`;
      break;
    default:
  }

  return title;
};

export const getFormatedDateAndMonth = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, 0);
  const month = String(date.getMonth() + 1).padStart(2, 0);
  const year = String(date.getFullYear()).slice(-2);
  return `${day}/${month}/${year}`;
};

export const copyToClipboard = (textToCopy) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy);
  } else {
    let textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
  }
};
