import { USERS_ME_API, USERS_API } from '../utils/constant';
import request from '../utils/request';

export const fetchUserApiData = async () => {
  return await request(USERS_ME_API);
};

export const fetchUsersApiData = async () => {
  return await request(USERS_API);
};
