export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const FRONT_URL = process.env.REACT_APP_FRONT_URL;
export const API = `${BACKEND_URL}/api`;
export const AUTH_TOKEN = 'authToken';
export const BEARER = 'Bearer';
export const PROJECTS_API = `${API}/projects`;
export const STATUSES_API = `${API}/statuses`;
export const USERS_ME_API = `${API}/users/me`;
export const LEADS_API = `${API}/leads`;
export const AGENT_LEADS_API = `${API}/agent-leads`;
export const USERS_API = `${API}/users`;

export const COLOR_STATUS = {
  deal: 'green',
  denial: 'red',
  new: 'blue',
  reviewing: 'purple',
  viewing: 'geekblue',
};
