import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Card, Checkbox, Col, Form, Input, message, Row, Spin, Typography } from 'antd';
import { get, set } from 'lodash';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { FRONT_URL } from '../../utils/constant';
import { getAuthURL, getFormTitle } from '../../utils/helpers';
import request from '../../utils/request';
import formJSON from './forms.json';

import './style.css';
import auth from '../../utils/auth';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/user/userSlice';

const renderLink = (authType) => {
  if (authType === 'login') {
    return (
      <>
        <div>
          Don't have an account? <Link to='/auth/register'>Sign Up</Link>
        </div>
        <div style={{ marginTop: '10px' }}>
          <Link to='/auth/forgot-password'>Forgot Password?</Link>
        </div>
      </>
    );
  }

  return (
    <>
      Already have an account? <Link to='/auth/login'>Sign In</Link>
    </>
  );
};

const getButtonText = (authType) => {
  if (authType === 'login') {
    return <>Sign In</>;
  }
  if (authType === 'register') {
    return <>Sign Up</>;
  }
  return <>Submit</>;
};

const GetFormItem = ({ item, ...props }) => {
  if (item?.type === 'checkbox') {
    return (
      <Form.Item valuePropName='checked' {...props}>
        <Checkbox {...props}>{item.label}</Checkbox>
      </Form.Item>
    );
  }
  if (item?.type === 'confirmPassword') {
    return (
      <Form.Item
        label={item.label}
        dependencies={['password']}
        rules={[
          ...item.rules,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
        {...props}
      >
        <Input.Password placeholder='Confirm Password' {...props} />
      </Form.Item>
    );
  }
  let inner;
  if (item?.type === 'password') {
    inner = <Input.Password placeholder='Password' {...props} />;
  } else {
    inner = <Input placeholder={item.placeholder} {...props} />;
  }

  return (
    <Form.Item label={item.label} rules={item.rules} {...props}>
      {inner}
    </Form.Item>
  );
};

export default function AuthPage() {
  const { authType } = useParams();
  // const { authType, id } = useParams();
  // const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState('');

  const redirectUser = useCallback(() => {
    navigate(`/`);
  }, [navigate]);

  useEffect(() => {
    if (auth.getToken()) {
      redirectUser();
    }
  }, [redirectUser]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const requestURL = getAuthURL(authType);

    try {
      const formValues = Object.keys(formJSON.data[authType]).reduce((res, key) => {
        return Object.assign(res, { [key]: values[key] });
      }, {});

      // This line is required for the callback url to redirect your user to app
      if (authType === 'forgot-password') {
        set(formValues, 'url', `${FRONT_URL}/auth/reset-password`);
      }

      const response = await request(requestURL, { method: 'POST', body: formValues });
      auth.setToken(response.jwt, formValues.rememberMe);
      dispatch(setUser(response.user));

      message.success(`${authType === 'login' ? 'Welcome back!' : 'Welcome!'}`);
      redirectUser();
    } catch (error) {
      console.error(error);
      setError(error?.message ?? 'Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  if (!Object.keys(formJSON.views).includes(authType)) {
    navigate('/auth/login');
  }

  const inputs = get(formJSON, ['views', authType], []);

  const initialValues = {};
  if (['login', 'register'].includes(authType)) {
    Object.assign(initialValues, { rememberMe: true });
  }

  return (
    <Row align='middle' style={{ height: '100%', overflow: 'auto' }}>
      <Col className='auth-column'>
        <Card title={getFormTitle(authType)}>
          {error ? (
            <Alert className='alert_error' message={error} type='error' closable afterClose={() => setError('')} />
          ) : null}
          <Form name='basic' layout='vertical' onFinish={handleSubmit} autoComplete='on' initialValues={initialValues}>
            {inputs.map((formItem) => (
              <GetFormItem item={formItem} key={formItem.name} name={formItem.name} />
            ))}
            <Form.Item>
              <Button type='primary' htmlType='submit' className='login_submit_btn'>
                {getButtonText(authType)}
                {isLoading && <Spin size='small' />}
              </Button>
            </Form.Item>
          </Form>
          <Typography.Paragraph className='form_help_text'>{renderLink(authType)}</Typography.Paragraph>
        </Card>
      </Col>
    </Row>
  );
}
