import { message } from 'antd';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjectsData, getProjects } from '../redux/leads/leadsSlice';

const useFetchProjects = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const projects = useSelector(getProjects);

  const getProjectsData = useCallback(
    async (force) => {
      if (projects.length && !force) {
        return projects;
      }
      setLoading(true);
      return dispatch(fetchProjectsData())
        .then((response) => {
          if (response.error) {
            throw new Error(response.error.message);
          }
        })
        .catch((error) => {
          const errorString = `Error While Getting Projects With Message: ${error}`;
          console.error(errorString);
          message.error('Error While Getting Projects');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, projects]
  );

  return { loading, getProjectsData, projects };
};

export default useFetchProjects;
