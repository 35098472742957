import { message } from 'antd';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatusesData, getStatuses } from '../redux/leads/leadsSlice';

const useFetchStatuses = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const statuses = useSelector(getStatuses);

  const getStatusesData = useCallback(
    async (force) => {
      if (statuses.length && !force) {
        return statuses;
      }
      setLoading(true);
      return dispatch(fetchStatusesData())
        .then((response) => {
          if (response.error) {
            throw new Error(response.error.message);
          }
        })
        .catch((error) => {
          const errorString = `Error While Getting Statuses With Message: ${error}`;
          console.error(errorString);
          message.error('Error While Getting Statuses');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, statuses]
  );

  return { loading, getStatusesData, statuses };
};

export default useFetchStatuses;
