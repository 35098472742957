import { Button, Space } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getUser, removeUser } from '../../redux/user/userSlice';
import auth from '../../utils/auth';
import Container from '../Container/Container';

import './styles.css';

const AppHeader = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogout = () => {
    auth.clearToken();
    dispatch(removeUser());
    navigate('/auth/login', { replace: true });
  };

  return (
    <div>
      <Container className='header-wrapper'>
        <Link to='/' className='header-logo' href='/'>
          <img style={{ width: '100%' }} src='/logo192.png' alt='Agent menu logo' />
        </Link>
        <Space className='auth_buttons'>
          {user ? (
            <>
              {/* <Link className='auth_button_login' to='/profile'>
                {user.username}
              </Link> */}
              <Link className='auth_button_login'>{user.username}</Link>
              <Button className='button' type='primary' onClick={handleLogout}>
                Logout
              </Button>
            </>
          ) : (
            <>
              <Link to='/auth/login'>Login</Link>
              <Link className='header-button' to='/auth/register' type='primary' component={Button}>
                <Button type='primary'>Sign Up</Button>
              </Link>
            </>
          )}
        </Space>
      </Container>
    </div>
  );
};

export default AppHeader;
