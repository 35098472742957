import React from 'react';
import { Layout } from 'antd';
import Sidebar from '../Sidebar/Sidebar';

import './style.css';
import Container from '../Container/Container';

const { Content } = Layout;

const MainLayout = ({ children }) => {
  return (
    <Container className='layout-container'>
      <Layout className='main-layout' style={{ height: '100%' }}>
        <Sidebar />
        <Content className='main-layout-content'>{children}</Content>
      </Layout>
    </Container>
  );
};

export default MainLayout;
