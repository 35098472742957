import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsersData, getUsers } from '../redux/user/userSlice';

const useFetchUsers = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const users = useSelector(getUsers);

  const getUsersData = useCallback(
    async (force) => {
      if (users.length && !force) {
        return users;
      }
      setLoading(true);
      return dispatch(fetchUsersData())
        .then((response) => {
          if (response.error) {
            throw new Error(response.error.message);
          }
        })
        .catch((error) => {
          const errorString = `Error While Getting Users Data With Message: ${error}`;
          console.error(errorString);
          return { error };
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, users]
  );

  return { loading, getUsersData, users };
};

export default useFetchUsers;
