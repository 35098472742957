import { useEffect, useState } from 'react';

const useScreenSize = () => {
  const [viewType, setViewType] = useState(null);
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    if (screenWidth < 768) {
      setViewType('mobile');
      return;
    }
    if (screenWidth < 1024) {
      setViewType('tablet');
      return;
    }
    setViewType('desktop');
  }, [screenWidth]);

  return {
    isMobile: viewType === 'mobile',
    isTablet: viewType === 'tablet',
    isDesktop: viewType === 'desktop',
  };
};

export default useScreenSize;
