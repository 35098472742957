import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData, getUser } from '../redux/user/userSlice';

const useFetchUser = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector(getUser);

  const getUserData = useCallback(
    async (force) => {
      if (userData && !force) {
        return userData;
      }
      setLoading(true);
      return dispatch(fetchUserData())
        .then((response) => {
          if (response.error) {
            throw new Error(response.error.message);
          }
        })
        .catch((error) => {
          const errorString = `Error While Getting Logged In User Details With Message: ${error}`;
          console.error(errorString);
          return { error };
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch]
  );

  return { loading, getUserData };
};

export default useFetchUser;
